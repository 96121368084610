import { useStaticQuery ,graphql } from "gatsby"

const GetWebsitesQuery = () => useStaticQuery(graphql`query GetWebsites {
    allSanityPost(
      filter: {priority: {}, categories: {elemMatch: {title: {eq: "Websites"}}}}
      sort: {fields: priority}
    ) {
      nodes {
        categories {
          title
        }
        priority
        title
        preview
        description
        id
        website
      }
    }
  }`)

const websitesDataFormatter = (data) => { 
    return data.map(
        (elm) =>{
            return{
                name: elm.title,
                ID: elm.id,
                description: elm.description,
                url: elm.preview,
                designs: elm.images,
                website: elm.website
            }
        } 
    )
}

const getWebsitesQueryArray = (data) => data.allSanityPost.nodes

const websitesCardsFormatter = (data) => {
    return data.map(
        (elm) => {
            return {
                title: elm.title,
                description: elm.description,
                preview: elm.preview,
                buttonText: "Visit website",
                ID: elm.id,
                category: elm.categories[0].title,
                website: elm.website
            }
        } 
    )
}

export {GetWebsitesQuery, websitesDataFormatter, getWebsitesQueryArray, websitesCardsFormatter}