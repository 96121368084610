import { Link } from "gatsby";
import React, { useMemo } from "react";
import { motion } from "framer-motion";

import { GetWebsitesQuery, websitesDataFormatter, getWebsitesQueryArray, websitesCardsFormatter } from "../functions/getWebsitesQuery"

import CardsContainer from "./CardsContainer";

const linkAnimations = {
    initial: {
        opacity: 0,
        x: -50,
        transition: {
            delay: 0.125,
            ease: "easeInOut",
            type: "spring",
            duration: 0.3,
            stiffness: 80,
            mass: 0.8
        }
    },
    animate: {
        opacity: 1,
        x: 0,
        transition: {
            delay: 0.125,
            ease: "easeInOut",
            type: "spring",
            duration: 0.3,
            stiffness: 80,
            mass: 0.8
        }


    },

    reHover: {
        transition: {
            delay: 0.025,
            type: "tween",
            duration: 0.01
        }


    },
    hover: {
        scale: 1.02,
        transition: {
            type: "tween",
            duration: 0.3
        }
    }
}


const WebsitesContainer = (props) => {
    const { cardsMobile, cardsTablet, cardsDesktop, limited, title } = props
    const queryDataRaw = GetWebsitesQuery()
    const queryDataArray = useMemo(
        () => getWebsitesQueryArray(queryDataRaw),
        []
    )
    const cardsData = useMemo(
        () => websitesCardsFormatter(queryDataArray),
        []
    )
    return <section id="websites">
        <CardsContainer
            title={title}
            cardsData={cardsData}
            cardsAction={({ website }) => {
                if (typeof window !== "undefined") {
                    window.location.href = website
                }
            }}

            cardsMobile={cardsMobile}
            cardsTablet={cardsTablet}
            cardsDesktop={cardsDesktop}
            limited={limited}

        ></CardsContainer>
        {
            (limited)
                ? <motion.div
                    className="text-secondary dark:text-complement-2 text-URL mt-4"
                    variants={linkAnimations}
                    initial="initial"
                    animate="animate"
                    exit="initial"
                >
                    <div className="mt-16">
                        <Link to="/websites"> Wanna see all the websites?</Link>
                        <br></br>
                        <Link to="/websites"> click here</Link>
                    </div>
                </motion.div>
                : null
        }
    </section>

}

export default WebsitesContainer;