import React, { useState } from "react"
import Helmet from 'react-helmet'
import { motion } from "framer-motion"

import face from "../images/Face_colored.svg"
import faceDark from "../images/Face_colored.svg"

import { ThemeContextProvider } from "../context/ThemeContext"
import { WindowResizeContextProvider } from "../context/WindowResizeContext"
import { DesignsViewerContextProvider } from "../context/DesignsViewerContext"

import DesignsContainer from "../components/DesignsContainer"
import WebsitesContainer from "../components/WebsitesContainer"
import ContactSection from "../components/ContactSection"
import Navbar from "../components/Navbar"
import TypingAnimation from "../components/TypingAnimation"
import useDarkTheme from "../customHooks/useDarkTheme"
import ThemeHelmet from "../components/ThemeHelmet"
import { Link } from "gatsby"

const IndexPage = () => {

	const [darkTheme, setdarkTheme] = useDarkTheme()

	const sectionAnimations = {
		initial: {
			x: -200,
			opacity: 0,
		},
		animate: {
			x: 0,
			opacity: 1,
			transition: {
				ease: "easeInOut",
				type: "spring",
				stiffness: 80,
				mass: 0.5,
				duration: 0.1,
				delayChildren: 0.2,
			}

		},
	}

	const titleAnimation = {
		initial: {
			opacity: 0,
			x: -50
		},
		animate: {
			opacity: 1,
			x: 0,
			transition: {
				delay: 0.15,
				type: "spring",
				stiffness: 80,
				mass: 1,
				duration: 0.5,
			}

		},
	}

	const heroSubtitleAnimation = {
		initial: {
			opacity: 0,
			x: -50

		},
		animate: {
			x: 0,
			opacity: 1,
			transition: {
				delay: 0.3,
				type: "spring",
				stiffness: 80,
				mass: 0.8,
				duration: 0.5,
			}

		},
	}

	const heroFirstParAnim = {
		initial: {
			opacity: 0,
			x: -50
		},
		animate: {
			x: 0,
			opacity: 1,
			transition: {
				delay: 0.45,
				type: "spring",
				stiffness: 80,
				mass: 0.8,
				duration: 0.5,
			}
		},
	}

	const heroSecondParAnim = {
		initial: {
			opacity: 0,
			x: -50
		},
		animate: {
			x: 0,
			opacity: 1,
			transition: {
				delay: 0.45,
				type: "spring",
				stiffness: 80,
				mass: 0.8,
				duration: 0.5,
			}
		},
	}

	const imageAnimation = {
		initial: {
			x: -50,
			opacity: 0,
			rotate: 0,

		},
		animate: {
			x: 0,
			opacity: 1,
			transition: {
				delay: 0.6,
				type: "spring",
				stiffness: 80,
				mass: 1,
				duration: 0.5,
			}

		},
	}

	const phrases = [
		"create beautiful websites that solve problems and are functional.",
		"design solutions that deliver a good user experience.",
		"create amazing user interfaces.",
		"solve problems, especially complex ones.",
		"code animations like this 😎."

	]

	const darkModeClass = (darkTheme) ? "dark" : ""


	return (
		<ThemeContextProvider value={{ darkTheme, setdarkTheme }}>
			<ThemeHelmet darkTheme={darkTheme}></ThemeHelmet>
			<WindowResizeContextProvider>

				<main
					className={`text-black-A text-base ${darkModeClass}`}
					id="home"
				>
					<Navbar activePage="Home" setDarkMode={setdarkTheme} darkMode={darkTheme}></Navbar>
					<motion.section className="dark:text-white-B  sm:ml-6 sm:mr-6 sm:pt-[108px] sm:pb-16 md:ml-10 md:mr-10  
						lg:mr-[78px] lg:ml-[78px] xlg:mr-[calc(calc(100%-1280px)/2)] xlg:ml-[calc(calc(100%-1280px)/2)]"
						variants={sectionAnimations}
						initial="initial"
						animate="animate"

					>
						<section className="grid sm:grid-cols-6 sm:gap-4 md:grid-cols-8 md:gap-8 lg:grid-cols-12" id="hero">
							<div className="col-span-6 space-y-4">
								<div className="space-y-6">
									<motion.h2 variants={titleAnimation} className="text-hero-title text-primary dark:text-white-B mb-4">Hi, my name is Diego Cruz.</motion.h2>
									<motion.p variants={heroSubtitleAnimation} className="text-hero-subtitle text-primary dark:text-white-B">
									Web developer and designer based in Mexico
									</motion.p>
								</div>

								<div className="space-y-2">
									{/*<motion.p variants={heroFirstParAnim}>
										I like to <TypingAnimation
													phrases={phrases}
													loop={true}
													speed={25}
													wait={12}
													tikDuration={500}
													delay={800}
													isActive={true}
												></TypingAnimation>
	</motion.p>*/}
									<div>
										{/*<motion.p variants={heroSecondParAnim}>
											Take a look at my work, and if you want to talk write me an email to
										</motion.p>*/}
										<motion.p className="m-0" variants={heroSecondParAnim}>
										<a className={((darkTheme) ? "text-complement-2" : "text-secondary") + " sm:text-3xl text-hero-cta"} href="mailto:hello@dicruzcar.xyz">
												<TypingAnimation
													phrases={["hello@dicruzcar.xyz"]}
													loop={false}
													speed={25}
													wait={12}
													tikDuration={500}
													delay={450}
													isActive={true}
												></TypingAnimation>
												&nbsp;
											</a>
										</motion.p>
									</div>
								</div>
							</div>
						</section>
						<section className="mt-16 space-y-24">
							<DesignsViewerContextProvider>
								<DesignsContainer
									cardsMobile={3}
									cardsTablet={4}
									cardsDesktop={5}
									limited={true}
									title="Designs"
								></DesignsContainer>
							</DesignsViewerContextProvider>
							<WebsitesContainer
								cardsMobile={3}
								cardsTablet={4}
								cardsDesktop={5}
								limited={true}
								title="Websites"
							></WebsitesContainer>
						</section>
						<section className="mt-32">
							<ContactSection darkMode={darkTheme}></ContactSection>
						</section>
					</motion.section>

				</main>
			</WindowResizeContextProvider>
		</ThemeContextProvider>

	)
}

export default IndexPage

export const Head = () => <title>dicruzcar - Portfolio</title>
